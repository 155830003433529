import React from 'react';
import { Box, Image, Spinner } from '@chakra-ui/react';

const LoadingScreen = () => {
  return (
    <Box
      position="fixed" // Ensure it stays in place
      top="0"
      left="0"
      width="100vw" // Full viewport width
      height="100vh" // Full viewport height
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bgGradient="linear(to-b, purple.700, pink.500, gray.900)" // Use the same gradient as the Home component
      color="white" // White text color
      zIndex="9999" // Ensure it's on top of other content
    >
      {/* Centered Logo Image */}
      <Image
        src="/logo512_transparent.png"
        alt="Loading Logo"
        width="50%" // Set the image width to 50% of the container
        maxWidth="200px"
        height="auto" // Maintain aspect ratio
        mb={8} // Margin bottom to create space between image and spinner
      />

      {/* Spinner with Black Border */}
      <Box
        border="2px solid black" // 2px black border around the spinner
        borderRadius="full" // Make sure the border is circular
        p="2" // Padding to ensure the spinner fits nicely inside the border
        display="inline-flex" // Make sure it wraps the spinner properly
      >
        <Spinner
          size="xl"
          thickness="14px"
          speed="1.2s"
          color="black" // White spinner color
        />
      </Box>
    </Box>
  );
};

export default LoadingScreen;
